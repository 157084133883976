import React from "react";

const GeneralTerms = () => {
  return (
    <>
      <div className="side-head">
        <h3>Terms and Conditions-1xbets.pro</h3>
      </div>
      <div className="announce-wrap">
        <p>General Terms and Conditions-1xbets.pro</p>
        <p>Description: Initial Terms and Conditions replacing general rules</p>
        <p>Introduction</p>
        <p>
          These terms and conditions and the documents referred and linked to
          below (the &ldquo;Terms&rdquo;) set out the basis upon which the
          website operated under the URL www.1xbets.pro (the
          &ldquo;Website&rdquo;) and its related or connected services
          (collectively, the &ldquo;Service&rdquo;) will be provided to you.
        </p>
        <p>
          Please read these terms very carefully as they form a binding legal
          agreement between you - our customer (the &ldquo;Customer&rdquo;) -
          and us. By opening an account (the &ldquo;Account&rdquo;) and using
          the Service you agree to be bound by these terms, together with any
          amendment which may be published from time to time.
        </p>
        <p>
          The Service is supplied by&nbsp;
          <br />
          Cric Infotech Ltd.
        </p>
        <p>
          Transactions and payment services are operated by Cric Infotech N.V a
          limited liability company registered in Curacao, with company
          registration number 152377 and holding a license no. 365/JAZ
          Sub-License GLH- OCCHKTW0777072017.
        </p>
        <p>
          1xbets will only communicate with Customers by email to their
          registered email address (the &ldquo;Registered Email Address&rdquo;)
          as provided when opening your 1xbets account: Communication from
          1xbets will be issued through the following:
        </p>
        <p>Email only: support@1xbets.pro</p>
        <p>General Terms</p>
        <p>
          We reserve the right to amend the terms (including to any documents
          referred and linked to below) at any time. When such amendment is not
          substantial, we may not provide you with prior notice. You will be
          notified in advance for material changes to the terms and may require
          you to re-confirm acceptance to the updated terms before the changes
          come into effect. If you object to any such changes, you must
          immediately stop using the service and the termination provisions
          below will apply. Continued use of the service indicates your
          agreement to be bound by such changes. Any bets not settled prior to
          the changed terms taking effect will be subject to the pre-existing
          terms.
        </p>
        <p>
          If at any time you are in any doubt about how to place bets or
          otherwise use the service you should refer back to these terms or
          contact our customer service department (Customer Service Department)
          at support@1xbets.pro
        </p>
        <p>1. Your Obligations</p>
        <p>You agree that at all times when using the Service:</p>
        <p>
          You are over 18 years of age (or over the age of majority as
          stipulated in the laws of the jurisdiction applicable to you) and can
          enter into a binding legal agreement with us.
        </p>
        <p>
          It is the User&rsquo;s responsibility to check and enter this site
          only if the user is in a country where it is lawful to place bets on
          the service (if in doubt, you should seek local legal advice). It is
          your responsibility to ensure that your use of the service is legal.
        </p>
        <p>
          When sending money to us you are authorised to do so e.g. you are the
          authorised user of the debit/credit card or other payment method you
          use.
        </p>
        <p>
          You will not, by participating in the Services and/or placing bets be
          placed in a position of actual, potential or perceived conflict of
          interest in any manner.
        </p>
        <p>
          You have never failed to pay, or attempted to fail to pay a liability
          on a bet.
        </p>
        <p>
          You are acting solely on your own behalf as a private individual in a
          personal capacity and not on behalf of another party or for any
          commercial purposes.
        </p>
        <p>
          By placing bets you may lose some or all of your money lodged with us
          in accordance with these terms and you will be fully responsible for
          that loss.
        </p>
        <p>
          You must use the service for legitimate betting purposes only and must
          not nor attempt to manipulate any market or element within the service
          in bad faith or in a manner that adversely affects the integrity of
          the Service or us.
        </p>
        <p>
          When placing bets on the service you must not use any information
          obtained in breach of any legislation in force in the country in which
          you were when the bet was placed.
        </p>
        <p>
          You must make all payments to us in good faith and not attempt to
          reverse a payment made or take any action which will cause such
          payment to be reversed by a third party in order to avoid a liability
          legitimately incurred.
        </p>
        <p>
          You must otherwise generally act in good faith in relation to us of
          the service at all times and for all bets made through the service.
        </p>
        <p>2. Registration</p>
        <p>You agree that at all times when using the service:</p>
        <p>
          In order to protect the integrity of the service and for other
          operational reasons we reserve the right to refuse to accept a
          registration application from any applicant at our sole discretion and
          without any obligation to communicate a specific reason.
        </p>
        <p>
          Before using the service, you must personally complete the
          registration form and read and accept these terms. In order to start
          betting on the service, we will require you to become a verified
          Customer which includes passing certain checks. You may be required to
          provide a valid proof of identification and any other document as it
          may be deemed necessary.
        </p>
        <p>
          This includes but is not limited to, a picture ID (copy of passport,
          driver&rsquo;s licence or national ID card) and a recent utility bill
          listing your name and address as proof of residence to the minimum. We
          reserve the right to suspend wagering or restrict Account options on
          any Account until the required information is received. This procedure
          is a statutory requirement and is done in accordance with the
          applicable gaming regulation and the anti-money laundering legal
          requirements. Additionally, you will need to fund your Skyexchange
          Account using the payment methods set out on the payment section of
          our Website.
        </p>
        <p>
          You must provide complete and accurate information about yourself,
          inclusive of a valid name, surname, address and email address, and
          update such information in the future to keep it complete and
          accurate. It is your responsibility to keep your contact details up to
          date on your account. Failure to do so may result in you failing to
          receive important account related notifications and information from
          us, including changes we make to these terms. We identify and
          communicate with our Customers via their Registered Email Address. It
          is the responsibility of the Customer to maintain an active and unique
          email account, to provide us with the correct email address and to
          advise Skyexchange of any changes in their email address. Each
          Customer is wholly responsible for maintaining the security of his
          Registered Email Address to prevent the use of his Registered Email
          Address by any third party. Skyexchange shall not be responsible for
          any damages or losses deemed or alleged to have resulted from
          communications between Skyexchange and the Customer using the
          Registered Email Address. Any Customer not having an email address
          reachable by Skyexchange will have his Account suspended until such an
          address is provided to us. We will immediately suspend your Account
          upon written notice to you to this effect if you intentionally provide
          false or inaccurate personal information. We may also take legal
          action against you for doing so in certain circumstances and/or
          contact the relevant authorities who may also take action against you.
        </p>
        <p>
          You are only allowed to register one Account with the service.
          Accounts are subject to immediate closure if it is found that you have
          multiple Accounts registered with us.
        </p>
        <p>
          This includes the use of representatives, relatives, associates,
          affiliates, related parties, connected persons and/ or third parties
          operating on your behalf.
        </p>
        <p>
          In order to ensure your financial worthiness and to confirm your
          identity, we may use any third party information providers we consider
          necessary.
        </p>
        <p>
          You must keep your password for the service confidential. Provided
          that the Account information requested has been correctly supplied, we
          are entitled to assume that bets, deposits and withdrawals have been
          made by you. We advise you to change your password on a regular basis
          and never disclose it to any third party. Passwords must contain at
          least one letter, one number and one special character and must be at
          least eight characters long. It is your responsibility to protect your
          password and any failure to do so shall be at your sole risk and
          expense. You must log out of the Service at the end of each session.
          If you believe any of your Account information is being misused by a
          third party, or your Account has been hacked into, or your password
          has been discovered by a third party, you must notify us immediately
          by email using your registered Email Address to
          support@skyexchange.com
        </p>
        <p>
          You must notify us if your registered email Address has been hacked
          into, we may, however, require you to provide additional information/
          documentation so that we can verify your identity. We will immediately
          suspend your Account once we are aware of such an incident. In the
          meantime you are responsible for all activity on your Account
          including third party access, regardless of whether or not their
          access was authorised by you.
        </p>
        <p>
          You must not at any time transmit any content or other information on
          the service to another Customer or any other party by way of a screen
          capture (or other similar method), nor display any such information or
          content in a frame or in any other manner that is different from how
          it would appear if such Customer or third party had typed the URL for
          the service into the browser line;
        </p>
        <p>
          When registering, you will be required to choose the currency in which
          you will operate your account. This will be the currency of your
          deposits, withdrawals and bets placed and matched into the service as
          set out in these terms. Some payment methods do not process in all
          currencies. In such cases a processing currency will be displayed,
          along with a conversion calculator available on the page.
        </p>
        <p>
          We are under no obligation to open an account for you and our website
          sign-up page is merely an invitation to treat. It is entirely within
          our sole discretion whether or not to proceed with the opening of an
          account for you and, should we refuse to open an Account for you, we
          are under no obligation to provide you with a reason for the refusal.
        </p>
        <p>
          Upon receipt of your application, we may be in touch to request
          further information and/ or documentation from you in order for us to
          comply with our regulatory and legal obligations.
        </p>
        <p>3. Restricted Use</p>
        <p>
          3.1 You must not use the Service:
          <br />
          if you are under the age of 18 years (or below the age of majority as
          stipulated in the laws of the jurisdiction applicable to you) or if
          you are not legally able to enter into a binding legal agreement with
          us;
        </p>
        <p>
          to collect nicknames, e-mail addresses and/or other information of
          other Customers by any means (for example, by sending spam, other
          types of unsolicited e-mails or the unauthorised framing of, or
          linking to, the Service);
        </p>
        <p>
          to disrupt or unduly affect or influence the activities of other
          Customers or the operation of the Service generally;.
        </p>
        <p>
          to promote unsolicited commercial advertisements, affiliate links, and
          other forms of solicitation which may be removed from the Service
          without notice;.
        </p>
        <p>
          in any way which, in our reasonable opinion, could be considered as an
          attempt to: (i) cheat the Service or another Customer using the
          Service; or (ii) collude with any other Customer using the Service in
          order to obtain a dishonest advantage;
        </p>
        <p>
          to scrape our odds or violate any of our Intellectual Property Rights;
          or.
        </p>
        <p>for any unlawful activity whatsoever.</p>
        <p>
          3.2 You cannot sell or transfer your account to third parties, nor can
          you acquire a player account from a third party.
          <br />
          3.3 You may not, in any manner, transfer funds between player
          accounts.
          <br />
          3.4 We may immediately terminate your Account upon written notice to
          you if you use the Service for unauthorised purposes. We may also take
          legal action against you for doing so in certain circumstances.
          <br />
          4. Privacy
        </p>
        <p>
          Any information provided to us by you will be protected and processed
          in strict accordance with these Terms and our Privacy Policy.
        </p>
        <p>
          We will not reveal the identity of any person who places bets using
          the service unless the information is lawfully required by competent
          authorities such as Regulators, the Police (e.g. to investigate fraud,
          money laundering or sports integrity issues), or by Financial Entities
          such as banks or payment suppliers or as permitted from time to time
          pursuant to the Privacy Policy.
        </p>
        <p>
          Upon registration , your information will be stored in our database.
          This means that your personal information may be transferred outside
          the European Economic Area (EEA) to jurisdictions that may not provide
          the same level of protection and security as applied within the EU or
          EEA. By agreeing to these Terms you agree to the transfer of your
          personal information for the purpose of the provision of the Service
          object of this agreement and as further detailed in our Privacy
          Policy.
        </p>
        <p>5. Your Account</p>
        <p>We accept Accounts in multiple currencies.</p>
        <p>We do not give credit for the use of the service.</p>
        <p>
          We may close or suspend an Account and refund any monies held if you
          are not or we reasonably believe that you are not complying with these
          Terms, or to ensure the integrity or fairness of the Service or if we
          have other reasonable grounds to do so. We may not always be able to
          give you prior notice.
        </p>
        <p>
          We reserve the right to suspend an account without prior notice and
          return all funds. Contractual obligations already matured will however
          be honoured.
        </p>
        <p>
          We reserve the right to refuse, restrict, cancel or limit any wager at
          any time for whatever reason, including any bet perceived to be placed
          in a fraudulent manner in order to circumvent our betting limits and/
          or our system regulations.
        </p>
        <p>
          If we close or suspend your account due to you not complying with
          these terms, we may cancel and/or void any of your bets.
        </p>
        <p>
          If any amount is mistakenly credited to your account it remains our
          property and when we become aware of any such mistake, we shall notify
          you and the amount will be withdrawn from your Account.
        </p>
        <p>
          If, for any reason, your account goes overdrawn, you shall be in debt
          to us for the amount overdrawn.
        </p>
        <p>
          You must inform us as soon as you become aware of any errors with
          respect to your Account.
        </p>
        <p>
          Customers have the right to self-exclude themselves from 1xbets.pro.
          These requests have to be received from the Customer&rsquo;s
          Registered Email Address and have to be sent to support@1xbets.pro
        </p>
        <p>
          Customers may set limitations on the amount they may wager and lose.
          Such request has to be sent from the Customer&rsquo;s Registered Email
          Address to support@1xbets.pro .Implementation and increasing of limits
          shall be processed diligently, however, any request for removing or
          reducing limitations shall be done after a cooling-off period of seven
          days following your request.
        </p>
        <p>
          Should you wish to close your account with us, please send an email
          from your Registered Email Address to support@1xbets.pro.
        </p>
        <p>6. Deposit of Funds</p>
        <p>
          You may deposit funds into your Account by any of the methods set out
          on our Website. All deposits should be made in the same currency as
          your Account and any deposits made in any other currency will be
          converted using the daily exchange rate obtained from www.oanda.com,
          or at our own bank&rsquo;s prevailing rate of exchange following which
          your Account will be deposited accordingly.
        </p>
        <p>
          Fees and charges may apply to customer&rsquo;s deposits and
          withdrawals. Any deposit made to an account which is not rolled over
          (risked) three times will incur a 3% processing fee and any applicable
          withdrawal fee. You are responsible for your own bank charges that you
          may incur due to depositing funds with us. Exceptions to this rule are
          outlined in our &ldquo;Payment Options&rdquo; pages.
        </p>
        <p>
          Skyexchange is not a financial institution and uses a third party
          electronic payment processors to process credit and debit card
          deposits; they are not processed directly by us. If you deposit funds
          by either a credit card or a debit card, your Account will only be
          credited if we receive an approval and authorisation code from the
          payment issuing institution. If your card&rsquo;s issuer gives no such
          authorisation, your account will not be credited with those funds.
        </p>
        <p>
          Your funds are deposited and held in the respective client account
          based on the currency of your account.
        </p>
        <p>
          We are not a financial institution and you will not be entitled to any
          interest on any outstanding account balances and any interest accrued
          on the client accounts will be paid to us.
        </p>
        <p>
          Funds originating from ill-gotten means must not be deposited with us.
        </p>
        <p>7. Withdrawal of Funds</p>
        <p>
          You may withdraw any or all of your account Balance within the
          transaction.
        </p>
        <p>Note that fees may apply as outlined in section 7.b.</p>
        <p>
          All withdrawals must be made in the currency of your account, unless
          otherwise stipulated by us.
        </p>
        <p>
          We reserve the right to request documentation for the purpose of
          identity verification prior to granting any withdrawals from your
          Account. We also reserve our rights to request this documentation at
          any time during the lifetime of your relationship with us.
        </p>
        <p>
          All withdrawals must be made to the original debit, credit card, bank
          account, method of payment used to make the payment to your
          skyexchange.com Account. We may, and always at our own discretion,
          allow you to withdraw to a payment method from which your original
          deposit did not originate. This will always be subject to additional
          security checks.
        </p>
        <p>
          Should you wish to withdraw funds but your account is either
          inaccessible, dormant, locked or closed, please contact our Customer
          Service Department at support@1xbets.pro
        </p>
        <p>8. Payment Transactions and Processors</p>
        <p>
          You are fully responsible for paying all monies owed to us. You must
          make all payments to us in good faith and not attempt to reverse a
          payment made or take any action which will cause such payment to be
          reversed by a third party in order to avoid a liability legitimately
          incurred. You will reimburse us for any charge-backs, denial or
          reversal of payment you make and any loss suffered by us as a
          consequence thereof. We reserve the right to also impose an
          administration fee of &euro;60, or currency equivalent per
          charge-back, denial or reversal of payment you make.
        </p>
        <p>
          We reserve the right to use third party electronic payment processors
          and or merchant banks to process payments made by you and you agree to
          be bound by their terms and conditions providing they are made aware
          to you and those terms do not conflict with these Terms.
        </p>
        <p>
          All transactions made on our site might be checked to prevent money
          laundering or terrorism financing activity. Suspicious transactions
          will be reported to the relevant authority depending on the
          jurisdiction governing the transaction.
        </p>
        <p>9. Errors</p>
        <p>
          In the event of an error or malfunction of our system or processes,
          all bets are rendered void. You are under an obligation to inform us
          immediately as soon as you become aware of any error with the service.
          In the event of communication or system errors or bugs or viruses
          occurring in connection with the service and/or payments made to you
          as a result of a defect or effort in the Service, we will not be
          liable to you or to any third party for any direct or indirect costs,
          expenses, losses or claims arising or resulting from such errors, and
          we reserve the right to void all games/bets in question and take any
          other action to correct such errors.
        </p>
        <p>
          In the event of a casino system malfunction, or disconnection issues,
          all bets are rendered void. In the event of such error or any system
          failure or game error that results in an error in any odds
          calculation, charges, fees, rake, bonuses or payout, or any currency
          conversion as applicable, or other casino system malfunction (the
          &ldquo;Casino Error&rdquo;), we reserve the right to declare null and
          void any wagers or bets that were the subject of such Casino Error and
          to take any money from your Account relating to the relevant bets or
          wagers.
        </p>
        <p>
          We make every effort to ensure that we do not make errors in posting
          lines. However, if as a result of human error or system problems a bet
          is accepted at an odd that is: materially different from those
          available in the general market at the time the bet was made; or
          clearly incorrect given the chance of the event occurring at the time
          the bet was made then we reserve the right to cancel or void that
          wager, or to cancel or void a wager made after an event has started.
        </p>
        <p>
          We have the right to recover from you any amount overpaid and to
          adjust your account to rectify any mistake. An example of such a
          mistake might be where a price is incorrect or where we enter a result
          of an event incorrectly. If there are insufficient funds in your
          Account, we may demand that you pay us the relevant outstanding amount
          relating to any erroneous bets or wagers. Accordingly, we reserve the
          right to cancel, reduce or delete any pending plays, whether placed
          with funds resulting from the error or not.
        </p>
        <p>10. General Rules</p>
        <p>
          If a sport-specific rule contradicts a general rule, then the general
          rule will not apply.
        </p>
        <p>
          The winner of an event will be determined on the date of the
          event&rsquo;s settlement; we do not recognise protested or overturned
          decisions for wagering purposes. The result of an event suspended
          after the start of competition will be decided according to the
          wagering rules specified for that sport by us.
        </p>
        <p>
          All results posted shall be final after 72 hours and no queries will
          be entertained after that period of time. Within 72 hours after
          results are posted, the company will only reset/correct the results
          due to human error, system error or mistakes made by the referring
          results source.
        </p>
        <p>
          Minimum and maximum wager amounts on all sporting events will be
          determined by us and are subject to change without prior written
          notice. We also reserve the right to adjust limits on individual
          Accounts as well.
        </p>
        <p>
          Customers are solely responsible for their own account transactions.
          Please be sure to review your wagers for any mistakes before sending
          them in. Once a transaction is complete, it cannot be changed. We do
          not take responsibility for missing or duplicate wagers made by the
          Customer and will not entertain discrepancy requests because a play is
          missing or duplicated. Customers may review their transactions in the
          My Account section of the site after each session to ensure all
          requested wagers were accepted.
        </p>
        <p>
          For a wager to have action on any named contestant in a Yes/No
          Proposition, the contestant must enter and compete in the event.
        </p>
        <p>
          We attempt to follow the normal conventions to indicate home and away
          teams by indicating the home and away team by means of vertical
          placement on the desktop site version. This means in American Sports
          we would place the home team on the bottom. For Non-US sports, we
          would indicate the home team on top. In the case of a neutral venue,
          we attempt to include the letter &ldquo;N&rdquo; next to the team
          names to indicate this. For the Asian and mobile versions, we do not
          distinguish between European and American Sports. On the Asian and
          mobile versions of the site, the home team is always listed first.
          However, we do not guarantee the accuracy of this information and
          unless there is an official venue change subsequent to bets being
          placed, all wagers have action.
        </p>
        <p>
          A game/match will have action regardless of the League heading that is
          associated with the matchup. For example, two teams from the same
          League are playing in a Cup competition. If the matchup is mistakenly
          placed in the League offering, the game/match will still have action,
          as long as the matchup is correct. In other words, a matchup will have
          action as long as the two teams are correct, and regardless of the
          League header in which it is placed on our Website.
        </p>
        <p>
          If an event is not played on the same date as announced by the
          governing body, then all wagers on the event have no action. If an
          event is posted by us, with an incorrect date, all wagers have action
          based on the date announced by the governing body.
        </p>
        <p>
          Skyexchange reserves the right to remove events, markets and any other
          product from the website.
        </p>
        <p>
          Skyexchange reserves the right to restrict the casino access of any
          player without prior notice.
        </p>
        <p>
          In all futures wagering (for example, total season wins, Super Bowl
          winner, etc.), the winner as determined by the Governing Body will
          also be declared the winner for betting purposes except when the
          minimum number of games required for the future to have
          &ldquo;action&rdquo; has not been completed.
        </p>
        <p>11. Communications and Notices</p>
        <p>
          All communications and notices to be given under these terms by you to
          us shall be sent to support@skyexchange.com
        </p>
        <p>
          All communications and notices to be given under these terms by us to
          you shall, unless otherwise specified in these terms, be either posted
          on the Website and/or sent to the Registered Email Address we hold on
          our system for the relevant Customer. The method of such communication
          shall be in our sole and exclusive discretion.
        </p>
        <p>
          All communications and notices to be given under these terms by either
          you or us shall be in writing in the English language when the service
          is not operated by Skyexchange, and must be given to and from the
          Registered Email Address in your Account.
        </p>
        <p>12. Matters Beyond Our Control</p>
        <p>
          We cannot be held liable for any failure or delay in providing the
          service due to an event of Force Majeure which could reasonably be
          considered to be outside our control despite our execution of
          reasonable preventative measures such as: an act of God; trade or
          labour dispute; power cut; act, failure or omission of any government
          or authority; obstruction or failure of telecommunication services; or
          any other delay or failure caused by a third party, and we will not be
          liable for any resulting loss or damage that you may suffer. In such
          an event, we reserve the right to cancel or suspend the Service
          without incurring any liability.
        </p>
        <p>13. Liability</p>
        <p>
          TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE WILL NOT COMPENSATE YOU
          FOR ANY REASONABLY FORESEEABLE LOSS OR DAMAGE (EITHER DIRECT OR
          INDIRECT) YOU MAY SUFFER IF WE FAIL TO CARRY OUT OUR OBLIGATIONS UNDER
          THESE TERMS UNLESS WE BREACH ANY DUTIES IMPOSED ON US BY LAW
          (INCLUDING IF WE CAUSE DEATH OR PERSONAL INJURY BY OUR NEGLIGENCE) IN
          WHICH CASE WE SHALL NOT BE LIABLE TO YOU IF THAT FAILURE IS ATTRIBUTED
          TO
        </p>
        <p>
          (I) YOUR OWN FAULT;
          <br />
          (II) A THIRD PARTY UNCONNECTED WITH OUR PERFORMANCE OF THESE TERMS
          (FOR INSTANCE PROBLEMS DUE TO COMMUNICATIONS NETWORK PERFORMANCE,
          CONGESTION, AND CONNECTIVITY OR THE PERFORMANCE OF YOUR COMPUTER
          EQUIPMENT); OR
          <br />
          (III) ANY OTHER EVENTS WHICH NEITHER WE NOR OUR SUPPLIERS COULD HAVE
          FORESEEN OR FORESTALLED EVEN IF WE OR THEY HAD TAKEN REASONABLE CARE.
          AS THIS SERVICE IS FOR CONSUMER USE ONLY WE WILL NOT BE LIABLE FOR ANY
          BUSINESS LOSSES OF ANY KIND. IN THE EVENT THAT WE ARE HELD LIABLE FOR
          ANY EVENT UNDER THESE TERMS, OUR TOTAL AGGREGATE LIABILITY TO YOU
          UNDER OR IN CONNECTION WITH THESE TERMS SHALL NOT EXCEED
          <br />
          (A) THE VALUE OF THE BETS AND OR WAGERS YOU PLACED VIA YOUR ACCOUNT IN
          RESPECT OF THE RELEVANT BET/WAGER OR PRODUCT THAT GAVE RISE TO THE
          RELEVANT LIABILITY, OR
          <br />
          (B) EUR &euro;500 IN AGGREGATE, WHICHEVER IS LOWER. WE STRONGLY
          RECOMMEND THAT YOU (I) TAKE CARE TO VERIFY THE SUITABILITY AND
          COMPATIBILITY OF THE SERVICE WITH YOUR OWN COMPUTER EQUIPMENT PRIOR TO
          USE; AND (II) TAKE REASONABLE PRECAUTIONS TO PROTECT YOURSELF AGAINST
          HARMFUL PROGRAMS OR DEVICES INCLUDING THROUGH INSTALLATION OF
          ANTI-VIRUS SOFTWARE.
          <br />
          14. Gambling By Those Under Age
        </p>
        <p>
          If we suspect that you are or receive notification that you are
          currently under 18 years or were under 18 years (or below the age of
          majority as stipulated in the laws of the jurisdiction applicable to
          you) when you placed any bets through the service your account will be
          suspended to prevent you placing any further bets or making any
          withdrawals from your account. We will then investigate the matter,
          including whether you have been betting as an agent for, or otherwise
          on behalf, of a person under 18 years (or below the age of majority as
          stipulated in the laws of the jurisdiction applicable to you). If
          having found that you: (a) are currently; (b) were under 18 years or
          below the majority age which applies to you at the relevant time; or
          (c) have been betting as an agent for or at the behest of a person
          under 18 years or below the majority age which applies:
        </p>
        <p>
          i. all winnings currently or due to be credited to your account will
          be retained;
          <br />
          ii. all winnings gained from betting through the service whilst under
          age must be paid to us on demand (if you fail to comply with this
          provision we will seek to recover all costs associated with recovery
          of such sums); and/or
          <br />
          iii. any monies deposited in your Skyexchange.com account which are
          not winnings will be returned to you.
          <br />
          This condition also applies to you if you are over the age of 18 years
          but you are placing your bets within a jurisdiction which specifies a
          higher age than 18 years for legal betting and you are below that
          legal minimum age in that jurisdiction.
        </p>
        <p>
          In the event we suspect you are in breach of the provisions of this
          Clause 15 or are attempting to rely on them for a fraudulent purpose,
          we reserve the right to take any action necessary in order to
          investigate the matter, including informing the relevant law
          enforcement agencies.
        </p>
        <p>15. Anti Money-Laundering and KYC Policy</p>
        <p>
          It is the policy of 1xbets to actively prevent the services of the
          firm being used to facilitate financial crime, money laundering and
          terrorist financing.
        </p>
        <p>
          Strict compliance with all applicable regulations will also protect
          the senior management and staff of the firm, as individuals, from the
          risks of breaches of the law, regulations, and supervisory
          requirements, and to preserve the reputation of the 1xbetsagainst the
          damage that could be caused by being implicated in money laundering
          and terrorist financing activities.
        </p>
        <p>To achieve these objectives, it is the policy of this firm that:</p>
        <p>
          Every staff member shall meet their personal obligations as
          appropriate to their role and responsibilities;
          <br />
          Commercial considerations cannot take precedence over 1xbets
          anti-money laundering commitment;
          <br />
          The firm shall appoint a Money Laundering Reporting Officer/Nominated
          Officer (&ldquo;MLRO&rdquo;), and a deputy to cover in their absence,
          and they shall be afforded every assistance and cooperation by all
          members of staff in carrying out their duties and responsibilities.
          The deputy will be the Director Chief Executive Officer (CEO).
          <br />
          Skyexchange will strictly comply with all applicable AML/CFT rules and
          regulations with specific emphasis on:
        </p>
        <p>
          A culture of compliance to be adopted and promulgated throughout the
          firm towards the prevention of financial crime;
          <br />A commitment to ensuring that customer&rsquo;s identities will
          be satisfactorily verified at required thresholds;
          <br />A commitment to &ldquo;knowing its customer&rdquo; appropriately
          - both at acceptance and throughout the business relationship -
          through taking appropriate steps to verify the customer&rsquo;s
          identity and monitoring their use Skyexchange services;
          <br />A commitment to ensuring that staff are trained and made aware
          of the law and their obligations under it, and to establishing
          procedures to implement these requirements; and
          <br />
          Recognition of the importance of staff promptly reporting their
          suspicions internally:
          <br />
          At the heart of our policies, procedures and controls, and consistent
          with FATF Recommendation 1, is the risk-based approach. The risk-based
          approach means that we focus our resources on the areas of greatest
          risk.
        </p>
        <p>
          Our policies, procedures and internal controls are designed to ensure
          compliance with all applicable anti-money laundering and
          anti-terrorist financing regulations and regulatory guidelines and
          will be reviewed and updated on a regular basis to ensure appropriate
          policies, procedures and internal controls are in place to account for
          both changes in regulations and changes in our business.
        </p>
        <p>15.1 Money Laundering and Terrorism Financing</p>
        <p>Money Laundering means:</p>
        <p>
          a. The conversion or transfer of property, knowing that such property
          is derived from criminal activity or from an act of participation in
          such activity, for the purpose of concealing or disguising the illicit
          origin of the property or of assisting any person who is involved in
          the commission of such an activity to evade the legal consequences of
          that person's action;
        </p>
        <p>
          b. The concealment or disguise of the true nature, source, location,
          disposition, movement, rights with respect to, or ownership of,
          property, knowing that such property is derived from criminal activity
          or from an act of participation in such an activity;
        </p>
        <p>
          c. The acquisition, possession or use of property, knowing, at the
          time of receipt, that such property was derived from criminal activity
          or from an act of participation in such an activity;
        </p>
        <p>
          d. Participation in, association to commit, attempts to commit and
          aiding, abetting, facilitating and counselling the commission of any
          of the actions referred to in points (a), (b) and (c).
        </p>
        <p>
          Money laundering shall be regarded as such even where the activities
          which generated the property to be laundered were carried out in the
          territory of another Member State or in that of a third country.
        </p>
        <p>
          Terrorism financing means the provision or collection of funds, by any
          means, directly or indirectly, with the intention that they be used or
          in the knowledge that they are to be used, in full or in part, in
          order to carry out any terrorist act.
        </p>
        <p>15.2 Organization of the AML/CFT function</p>
        <p>15.2.1 Senior Management</p>
        <p>
          Senior Management are responsible for the overall compliance policy of
          1xbets and ensuring adequate resources are provided for the proper
          training of staff and the implementing of risk systems. This includes
          computer software to assist in oversight. Senior management will
          receive and consider the monthly compliance reports sent by the MLRO
          and authorise changes based on the recommendations if required.
        </p>
        <p>
          Senior Management consists of the Board of Directors, the Executive
          Management Committee and the MLRO.
        </p>
        <p>15.2.2 The Nominated Officer/Money Laundering Reporting Officer</p>
        <p>
          Responsible for receiving internal disclosures and making reports to
          the Gaming Control Board, Curacao. First point of contact for all
          compliance issues from staff. Prepares monthly report for
          consideration of senior management and conducts risk assessments of
          compliance systems. Undertakes regular random analysis of transactions
          including assessment of documentary evidence provided by customers.
          Assists in making any necessary amendments to AML Policy in line with
          risk assessment. Ensures everyone is periodically informed of any
          changes in anti- money laundering or anti-terrorist financing
          legislation, policies and procedures, as well as current developments
          and changes in money laundering or terrorist activity financing
          schemes particular to their jobs. Reviews customer identification
          information to ensure that all the necessary information has been
          obtained. Establishes and implement the risk scoring matrix following
          regulatory guidance and for review and approval by Senior Management.
        </p>
        <p>15.2.3 Staff</p>
        <p>
          Responsible for knowing the AML Compliance Policy and understanding
          responsibilities. Ensure company procedures are adhered to and
          obtaining all documentary evidence as outlined within the manual.
          Ensure that all suspicious and unusual activity is reported to the
          MLRO.
        </p>
        <p>15.3 The Compliance Programme</p>
        <p>5.1 The Money Laundering Reporting Officer (MLRO)</p>
        <p>
          SKYEXCHANGE has appointed a MLRO with full responsibility for
          1xbets&rsquo;s anti-money laundering compliance.
        </p>
        <p>The MLRO:</p>
        <p>
          Will monitor the day-to-day operation of 1xbets TRADING&rsquo;s
          AML/CFT policies and respond to any reasonable request made by the law
          enforcement and/or the Gaming Control Board, Curacao.
          <br />
          Has the authority to act independently in carrying out their
          responsibilities, which includes direct access to the Gaming Control
          Board, Curacao and appropriate law enforcement agencies, in order that
          any suspicious activity may be reported to the right quarter as soon
          as is practicable.
          <br />
          Has the authority and the resources necessary to discharge their
          responsibilities effectively.
          <br />
          Is from a senior level and has direct access to senior management and
          the board of directors.
          <br />
          May choose to delegate certain duties to other employees, but wherever
          such a delegation is made, the MLRO retains ultimate responsibility
          for the implementation of the compliance regime.
          <br />
          At least annually, the MLRO will issue a report (the MLRO Report) to
          the senior management of 1xbets on the operation and effectiveness of
          the money laundering controls. This report will cover improvements,
          remedial programmes, the outcome of any internal audit reviews of the
          AML/CFT processes, and other relevant items.
          <br />
          15.4 Compliance Policies and Procedures
        </p>
        <p>
          1xbets has policies and procedures to assess the risks related to
          money laundering and terrorist financing. These policies and
          procedures are:
        </p>
        <p>
          Written and maintained by the MLRO under the supervision of senior
          management
          <br />
          Approved by senior management
          <br />
          Communicated, understood and adhered to by everyone dealing with
          customers or their transactions, including those who work in the areas
          relating to customer identification, record keeping, and reportable
          transactions, who need enough information to process and complete a
          transaction properly as well as to ascertain the identity of customers
          and keep records as required.
          <br />
          Policies and procedures which incorporate the reporting, record
          keeping, customer identification, risk assessment and risk mitigation
          requirements applicable.
          <br />
          Although directors and senior officers may not be involved in
          day-to-day compliance, they need to understand the statutory duties
          placed upon them, their staff and 1xbets itself.
        </p>
        <p>15.4.1 The Risk-Based Approach</p>
        <p>
          In money laundering and terrorist financing, a risk-based approach
          covers the following:
        </p>
        <p>
          The risk assessment of customer relationships and business activities;
          <br />
          The risk mitigation to implement controls to handle identified risks;
          <br />
          Keeping customer identification, beneficial ownership and business
          relationship information up to date; and
          <br />
          The ongoing monitoring of business relationships and transactions.
          <br />
          Existing regulatory obligations, such as for customer identification,
          are a minimum baseline requirement. Where enhanced due diligence is
          appropriate, a principle of the risk-based approach is to focus
          resources where they are most needed to manage risks within our
          tolerance level.
        </p>
        <p>15.4.2 Risk Mitigation</p>
        <p>
          Risk mitigation is implementing controls to limit the potential money
          laundering and terrorist financing risks identified in the risk
          assessment so as to stay within the risk tolerance level. When the
          risk assessment determines that risk is high for money laundering or
          terrorist financing, then we will develop risk mitigation strategies
          and apply them.
        </p>
        <p>In all situations, risk mitigation controls and measures include:</p>
        <p>
          Focussing on operations (products and services, customers and business
          relationships, geographic locations, and any other relevant factors)
          that are more vulnerable to abuse by money launderers and criminals;
          <br />
          Informing senior management of compliance initiatives, identified
          compliance deficiencies, corrective action taken, and suspicious
          transaction reports filed;
          <br />
          Providing for program continuity despite changes in management,
          employees or structure;
          <br />
          Focussing on meeting all regulatory record keeping and reporting
          requirements, recommendations for anti-money laundering and
          anti-terrorist financing compliance and providing for timely updates
          in response to changes in requirements;
          <br />
          Enabling the timely identification of reportable transactions and
          ensure accurate filing of required reports;
          <br />
          Incorporating anti-money laundering and anti-terrorist financing
          compliance into job descriptions and performance evaluations of
          appropriate personnel; and
          <br />
          Providing for adequate supervision and training of employees that
          handle currency transactions, complete reports, monitor for suspicious
          transactions, or engage in any other activity that forms part of the
          anti-money laundering and anti-terrorist financing program.
          <br />
          Increasing awareness of high risk situations within all business
          lines;
          <br />
          Increasing the frequency of ongoing monitoring of transactions or
          business relationships;
          <br />
          Escalating the approval of the establishment of an account or
          relationship even if not otherwise required to do so;
          <br />
          Increasing the levels of ongoing controls and reviews of
          relationships;
          <br />
          Requesting high-risk customers provide additional, documented
          information regarding controls they have implemented to safeguard
          their operations from abuse by money launderers and terrorists;
          <br />
          Verifying the identity of customers by reference to reliable,
          independent source documents, data or information;
          <br />
          Preventing any transaction with a potential customer until
          identification and account opening information has been obtained;
          <br />
          Implementing an appropriate process to approve all relationships
          identified as high-risk as part of the customer acceptance process or
          declining to do business with potential customers because they exceed
          1xbets&rsquo;s risk tolerance level;
          <br />
          Implementing a process to exit from an existing high-risk relationship
          which management sees as exceeding SKYEXCHANGE&rsquo;s risk tolerance
          level.
          <br />
          15.4.3 Risk Assessment
        </p>
        <p>
          SKYEXCHANGE is required to and will analyse potential threats and
          vulnerabilities to money laundering and terrorist financing to which
          the business may be exposed to in a risk assessment.
        </p>
        <p>The risk assessment will document and consider the following:</p>
        <p>
          Products, services and delivery channels
          <br />
          Geographic locations and areas of operation
          <br />
          Customers
          <br />
          The risk assessment may identify high-risk situations for which risk
          mitigation controls and monitoring may be required. The risk
          assessment is not static and will change over time.
        </p>
        <p>
          When a customer is identified as high-risk, they are subject to more
          frequent ongoing monitoring and updating of customer identification
          information, as well as any other appropriate enhanced measures.
        </p>
        <p>
          Skyexchange shall perform an initial risk assessment at the beginning
          of a new customer relationship and for existing customers on an
          ongoing basis.
        </p>
        <p>15.5 Client and Business Risk</p>
        <p>15.5.1 Products, Services and Delivery Channels</p>
        <p>
          1xbets will identify products and services or combinations of them
          that may pose an elevated risk of money laundering or terrorist
          financing. For example, products and services that support the
          movement and conversion of assets into, through and out of the
          financial system pose a high risk. Certain services have also been
          identified by financial regulators, governmental authorities or other
          credible sources as being potentially high-risk for money laundering
          or terrorist financing.
        </p>
        <p>
          Wire transactions;
          <br />
          Transactions involving third parties;
          <br />
          Non-face-to-face business relationships.
          <br />
          15.5.2 Geographic Locations and Areas of Operation
        </p>
        <p>
          Certain geographic locations potentially pose an elevated risk for
          money laundering and terrorist financing. These have been described by
          the FATF and by other resources such as Transparency International.
        </p>
        <p>
          Clients from, or identified as linked to these countries will be
          regarded as high risk:
        </p>
        <p>
          Any country subject to sanctions, embargoes, or similar measures;
          <br />
          Any country identified by credible sources as providing funding or
          support for terrorist activities, or that have designated terrorist
          organisations operating within their country;
          <br />
          Any country known to be a tax haven, source of narcotics or other
          significant criminal activity;
          <br />
          Any country identified by the Financial Action Task Force (FATF) as
          non-cooperative in the fight against money laundering or terrorist
          financing or subject to a FATF statement;
          <br />
          Any country identified by credible sources as lacking appropriate
          money laundering or terrorist financing laws and regulations, or as
          having significant levels of corruption.
          <br />
          SKYEXCHANGE does not do business with or provide services to anyone in
          any country belonging to a list of select countries subject to
          comprehensive international sanctions.
        </p>
        <p>15.5.3 Customer Relationships</p>
        <p>
          The risk assessment requires to know your customers
          (&ldquo;KYC&rdquo;). This is not limited to identification or
        </p>
        <p>
          record keeping, but it is also about understanding the customers
          &ndash; including their activities,
        </p>
        <p>transaction patterns, and how they operate.</p>
        <p>Examples of the factors that will be considered are:</p>
        <p>
          How long we have known the customer and had a business relationship;
          <br />
          Customers wanting to carry out large transactions;
          <br />
          Customers with regulatory or enforcement issues;
          <br />
          Customers with multiple online complaints;
          <br />
          Customers whose identification is difficult to verify;
          <br />
          Customers whose who are politically exposed.
          <br />
          Each customer is risk-rated as either posing a low, medium or high
          risk of money laundering and/or
        </p>
        <p>
          terrorist financing. The rating given at the beginning of the business
          relationship may be adjusted
        </p>
        <p>
          after the re-assessment and may change over time. The level of
          customer due diligence will vary
        </p>
        <p>
          depending on the risk rating of the customer at the beginning of the
          relationship and as it changes.
        </p>
        <p>
          For all high-risk players, enhanced due diligence is applied right
          away.
        </p>
        <p>15.5.4 Regulatory risk</p>
        <p>
          Regulatory risk means not meeting our obligations under applicable
          legislation. Examples of breaches of regulatory obligations include:
        </p>
        <p>Customer identification not done properly;</p>
        <p>
          Failure to train staff adequately;
          <br />
          Bot having an anti-money laundering and anti-terrorist financing
          (AML/CFT) program;
          <br />
          Failure to report suspicious transactions;
          <br />
          Not having an MLRO;
          <br />
          Failing to keep complete customer records.
          <br />
          15.5 Compliance Training
        </p>
        <p>
          1xbets has a training program for all relevant employees and other
          individuals who act on behalf of the Company. The goal of this
          training is to make sure that those who have contact with customers,
          who see customer transaction activity or who handle funds in any way
          understand the reporting, customer identification and record keeping
          requirements.
        </p>
        <p>
          All new employees of 1xbets are required to complete anti-money
          laundering and terrorist financing compliance training within their
          first three months of employment, and for customer-facing staff
          training is delivered prior to dealing with customers.
        </p>
        <p>
          Training is currently conducted through a customised course. The
          training program is delivered electronically (via a learning
          management system). The training program is reviewed and updated by
          the MLRO to reflect requirements as they change. The compliance
          training includes a test which everyone must pass in order to consider
          their training complete.
        </p>
        <p>
          To ensure employee training is kept up to date, all existing employees
          will receive follow up training on new and existing AML and regulatory
          requirements on a regular basis (at least within one year of their
          last training). If the online training test results show that a staff
          member does not understanding the training material, SKYEXCHANGE will
          ensure that the staff member receives specialised one-on-one training
          to understand the material.
        </p>
        <p>
          An employee log of assigned and completed training materials is kept
          by the MLRO and the Human Resources department for administrative
          purposes. Retention of these records is for a period of five years.
        </p>
        <p>
          Compliance training is adjusted in accordance to the employee&rsquo;s
          role within the company. The MLRO will review functions and arrange to
          provide suitable and customised training.
        </p>
        <p>Our training will include at a minimum:</p>
        <p>
          An understanding of the reporting, customer identification and record
          keeping requirements as well as penalties for not meeting those
          requirements;
          <br />
          Making all employees aware of the internal policies and procedures for
          deterring and detecting money laundering and terrorist financing that
          are associated with their jobs;
          <br />
          Delivering to employees and suppliers a clear understanding of their
          responsibilities under these policies and procedures;
          <br />
          All those who have contact with customers, who see customer
          transaction activity, who handle cash or funds in any way or who are
          responsible for implementing or overseeing the compliance regime must
          understand the reporting, customer identification and record keeping
          requirements.
          <br />
          Making all employees aware of how 1xbets is vulnerable to abuse by
          criminals laundering the proceeds of crime or by terrorists financing
          their activities;
          <br />
          Making all employees and agents aware that they cannot disclose that
          they have made a suspicious transaction report, or disclose the
          contents of such a report, with the intent to prejudice a criminal
          investigation, whether it has started or not;
          <br />
          That all employees and agents understanding that no criminal or civil
          proceedings may be brought against them for making a report in good
          faith;
          <br />
          Background information on money laundering so everyone who needs to
          can understand what money laundering is, why criminals choose to
          launder money and how the process usually works;
          <br />
          Details of what terrorist financing is and how that process usually
          works.
          <br />
          The MLRO is responsible for ensuring that everyone is informed of
          changes in anti-money laundering or anti-terrorist financing
          legislation, policies and procedures, and current developments in
          money laundering or terrorist activity financing schemes particularly
          relevant to their jobs.
        </p>
        <p>
          Certain employees, such as those in compliance, customer services and
          operations, require types of specialised additional training which
          will be provided either through external services or internally. The
          training program is reviewed and updated to reflect changes in
          requirements.
        </p>
        <p>15.6. Minimum standards &ndash; KYC POLICY</p>
        <p>
          1xbets has established standards regarding Know-Your-Customer
          (&ldquo;KYC&rdquo;). These standards require due diligence on each
          prospective customer before entering into a business relationship via
          identification and verification of his identity and, as the case may
          be, his representatives and beneficial owners on the basis of
          documents, data or information obtained from a reliable and
          independent source compliant with the domestic and European AML/CFT
          legislation and regulation.
        </p>
        <p>
          Interpretation of the KYC principle begins with identification of the
          customer by means of the necessary identification documents.
        </p>
        <p>
          That identification, completed by other information gathered, enables
          the Customer Acceptance Policy to be applied.
        </p>
        <p>
          In addition to these objective criteria, there are subjective elements
          which may arouse suspicions regarding a customer and to which
          particular attention should be paid.
        </p>
        <p>
          Finally, as KYC does not involve static data, but dynamic data through
          the relationship with the customer, it also needs follow-up and
          ongoing monitoring of the customer.
        </p>
        <p>15.6.1. Customer identification and verification (KYC)</p>
        <p>
          The formal identification of customers on entry into commercial
          relations is a vital element, both for the regulations relating to
          money laundering and for the KYC policy.
        </p>
        <p>
          This identification relies on the following fundamental principles :
        </p>
        <p>
          Each customer (= each individual person and/or each person involved in
          the case of a legal entity) must be identified by means of original
          supporting documents.
          <br />
          These documents will be recorded in a centralised system.
          <br />
          The identification must be completed by "face-to-face" contact.
          <br />
          Distance identification is also authorised and possible within a
          dedicated acceptance process, but limits the opportunity to carry out
          certain transactions or to access certain products.
          <br />
          Each person identified must be registered by IT means.
          <br />A person will not be accepted as a customer if the
          identification process proves to be incomplete.
          <br />
          The specific case of the due diligence exercised on the acceptance of
          politically exposed persons (PEP).
        </p>
        <p>
          Concrete application at 1xbets is reflected by a specific
          identification procedure for customers referenced as PEP, whatever
          their place of residence.
        </p>
        <p>15.6.2. Risk Profile calculation</p>
        <p>
          To assist in determining the level of AML/CFT due diligence to be
          exercised with regard to the Customer, a &ldquo;Compliance&rdquo; risk
          profile is calculated first of all on entry into relations (Low,
          Medium, High), and is then recalculated daily.
        </p>
        <p>15.6.3. Customer acceptance policy</p>
        <p>15.6.3.1. Customer Identification</p>
        <p>
          Client Identification is intended to confirm the existence and
          identify the individual with which SKYEXCHANGE has a business
          relationship and obtain supporting identity information. This includes
          measures to:
        </p>
        <p>
          Confirm the existence of the individual through identification
          documents;
          <br />
          Conduct due diligence into the identity of the customer including:
          <br />
          Cross-referencing customer names against government watch lists;
          <br />
          Determining whether the customers are politically exposed persons;
          <br />
          Determining whether any third parties are involved;
          <br />
          Reviewing relevant publicly available information on the customer
          (e.g. adverse media, social media);
          <br />
          Reviewing their background, reputation;
          <br />
          Keep records of all information and documents obtained.
          <br />
          15.6.3.2. Identifying Information for Customers
        </p>
        <p>
          This section sets out the standard identification requirements for
          individuals who have entered into a business relationship with 1xbets.
          This outline is likely to be sufficient for most situations. If,
          however, the customer is assessed as presenting a higher money
          laundering or terrorist financing risk, in line with our identified
          risk matrix, then 1xbets requires additional identity information to
          be provided and increase the level or verification accordingly.
        </p>
        <p>
          Where the result of the standard verification check gives rise to
          concern or uncertainty over identity, the number of matches that will
          be required to be reasonably satisfied as to the individual&rsquo;s
          identity will increase. Any concerns must be reported to the MLRO.
        </p>
        <p>
          If a customer is unable to produce a primary ID, the reasons for this
          should be noted and they may not be able to maintain an account.
        </p>
        <p>All documentary evidence must be recent.</p>
        <p>
          Passports and drivers&rsquo; licenses should be valid and not expired
          <br />
          Utility bills should be dated within the last 3 months
          <br />
          In terms of beneficial ownership, we will ask every customer whether
          they are acting in their own capacity or on behalf of another person.
          If they are acting for another person then we will require details of
          such.
        </p>
        <p>Sources of evidence</p>
        <p>Proof of Identity - Acceptable photo identity:</p>
        <p>
          Valid passport; or
          <br />
          Valid photo card driving licence (full or provisional); or
          <br />
          National identity card (non-UK nationals issued by EEA member states
          and Switzerland); or
          <br />
          Identity card issued by the Electoral Office for Northern Ireland
          <br />
          Proof of Identity - Acceptable non-photo evidence of identity:
        </p>
        <p>
          Documents issued by a government department, incorporating the
          person&rsquo;s name and residential address or their date of birth.
          E.g. (for proof of address or date of birth):
        </p>
        <p>
          Instrument of a court appointment (such as a grant of probate,
          bankruptcy); or
          <br />
          Current council tax demand letter or statement; or
          <br />
          Current (within the last 3 months) bank statements, or credit/debit
          card statements issued by a regulated financial sector firm in the UK,
          EU or equivalent jurisdiction; or
          <br />A recent (last available) utility bill (gas, water, electricity,
          telephone); it must be a bill or statement of account (not
          correspondence); statements printed off the internet are not
          acceptable on their own (a secondary check will be required such as a
          reference to a telephone directory including an online telephone
          directory); or
          <br />
          Valid photo card driving licence (full or provisional); or
          <br />A solicitor&rsquo;s letter confirming recent house purchase or
          land previous address.
          <br />
          When accepting evidence of identity from a customer, it is important
          that we make sufficient checks on the evidence provided to satisfy us
          of the customer&rsquo;s identity, and that we keep a record of the
          checks made. This will be done by the MLRO or under the supervision of
          the MLRO.
        </p>
        <p>Usual checks on photo ID may include:</p>
        <p>Likeness against the customer</p>
        <p>
          Does the date of birth on the evidence match the apparent age of the
          customer?
          <br />
          Is the ID valid?
          <br />
          Is the spelling of names the same as other documents provided by the
          customer?
          <br />
          Checks on secondary evidence of ID may include:
        </p>
        <p>
          Do the addresses match the address given on the photo ID?
          <br />
          Does the name of the customer match with the name on the photo ID?
          <br />
          15.6.3.3. Identity Verification Triggers
        </p>
        <p>
          Verification of the identity of the customer occurs either when the
          customer has reached the &euro;2,000 threshold or if the player is
          considered to be a high-risk player upon registration. The main
          trigger for Identity Verification is considered a single transaction
          (or smaller, linked transactions) that reach or exceed &euro;2,000
          either at deposit or withdrawal stage. For example, transactions will
          be considered as linked when they are carried out by the same customer
          during a single period of being logged on to the SKYEXCHANGE website
          or if they are carried out in 48 hours.
        </p>
        <p>
          In all cases, the customer will receive a notification to upload
          identity verification documents listed above. Each document that is
          provided by customers to SKYEXCHANGE is checked manually by dedicated
          staff.
        </p>
        <p>Further events that may trigger Identity Verification processes:</p>
        <p>
          When a customer requests a withdrawal to a different account than one
          that was used to make a deposit (e.g. due to the fact that certain
          payment service providers do not support incoming payments or other
          reasons). In these cases, the withdrawal requests will be immediately
          deducted from the customer&rsquo;s account, however, will not be
          processed until the customer&rsquo;s identity has been verified to
          ensure that the funds are going to the right person;
          <br />
          When a customer makes multiple deposits within a short timeframe (five
          or more deposits in one hour);
          <br />
          Staff identifies suspicious deposit patterns and or several payment
          cards are used (three or more different payment methods used within
          one week);
          <br />
          Registration country and IP do not match &ndash; In such cases, the
          player account may not be blocked and the player may still make
          deposits and play games, however, no withdrawal will be allowed until
          verification has been carried out;
          <br />
          When a deposit exceeds &euro;5,000.
          <br />
          Copies will be kept of all the documents used for identification.
        </p>
        <p>15.6.3.4. Additional Documents</p>
        <p>
          Additional documents may be requested from customers to substantiate
          their activities or to better understand their patters:
        </p>
        <p>
          Proof of Bank Account &ndash; This may be a screenshot or copy of the
          bank statement stating the customer&rsquo;s name and IBAN and the name
          of the bank (for instances when a customer requests a withdrawal to be
          deposited to a bank account);
          <br />
          Proof of Payment Card &ndash; This may be a photo or copy of the front
          side of the card in question or a bank statement that will include
          both the card details and the name of the customer (for instances when
          suspicious deposit patters are identified) &ndash;
          <br />
          To protect the customer&rsquo;s card data, SKYEXCHANGE informs them
          that they may obfuscate some of the card details, however, the
          customer&rsquo;s name, as well as the first and last four digits of
          the card number must be visible.
          <br />
          Bank Statements and Payslips &ndash; These may be requested from
          customers displaying potentially problematic gambling patterns and
          when external information available on the customer does not validate
          the amounts being gambled. In such cases SKYEXCHANGE would ask its
          higher volume customers for documents which would support the funds
          being spent on gambling.
          <br />
          15.6.3.5.Mitigation of Impersonation Risk
        </p>
        <p>
          Various additional customer identity checks are permitted on a
          risk-based approach. These checks may include:
        </p>
        <p>
          Requiring the first payment to be carried out through an account in
          the customer&rsquo;s name with an EU/EEA regulated credit institution
          or one from an equivalent jurisdiction;
          <br />
          Verifying additional aspects of the customer&rsquo;s identity, or of
          their electronic &lsquo;footprint&rsquo;;
          <br />
          Telephone contact with the customer prior to opening the account on a
          home or business number which has been verified (electronically or
          otherwise) or a call to the customer before further transactions are
          permitted, using it to verify additional aspects of personal identity
          information that have been previously provided during the setting up
          of the account;
          <br />
          15.6.3.6. Keeping Information Up-to-Date
        </p>
        <p>
          Once the identity of a customer has been confirmed, it does not have
          to be confirmed again. However, if there is any doubt about the
          information held, then that identification will be obtained again,
          including the identification of the customer.
        </p>
        <p>
          1xbets may review the customer documentation and then update it as
          appropriate. Any changes to identification such as a change of name of
          the customer would require new documents to be obtained.
        </p>
        <p>15.6.4. Ongoing transaction monitoring</p>
        <p>
          There are certain automated controls in place to monitor customer
          activities, for example:
        </p>
        <p>
          First time deposits;
          <br />
          Cash-out alerts for cash-outs greater than &euro;1,000;
          <br />
          Activity alerts when customer pays to, or stakes with the casino
          &euro;2,000 during any period of 24 hours;
          <br />
          Credit Card BIN alerts (when Credit Card country differs from player
          account country/IP);
          <br />
          In addition, all cash-out requests are processed manually by the
          Payments Team and are reviewed on a daily basis. Every request
          undergoes security checks before approval in order to determine that
          the origin of the funds/wins is compliant with the Gaming Control
          Board Curacao Policy and the terms and conditions of the Company.
        </p>
        <p>15.7. Organization of internal control</p>
        <p>
          Any member of 1xbets can report suspicious activities to the MLRO, as
          1xbets&rsquo;s Nominated Officer. A suspicious activity is one where
          it is known, or suspected, there are reasonably grounds to know or
          suspect that a person is engaged in, or attempting, money laundering
          or terrorist financing.
        </p>
        <p>15.7.1. Suspicious transactions reporting</p>
        <p>
          Having knowledge means knowing something to be true. Knowledge can be
          inferred from the surrounding circumstances; so, for example, a
          failure to ask obvious questions may imply knowledge. The knowledge
          must, however, have come to 1xbets during the course of business, or
          from a disclosure (to the MLRO).
        </p>
        <p>
          Suspicion is more subjective and falls short of proof based on firm
          evidence. Suspicion is more than speculation and is based on some
          foundation.
        </p>
        <p>
          An unusual transaction is not necessarily suspicious. Unusual patterns
          of gambling, including the spending of particularly large amounts of
          money in relation to the casino or customer&rsquo;s profile, should
          receive attention, but unusual behaviour should not necessarily lead
          to grounds for knowledge or suspicion of money laundering, or the
          making of a report to the FIAU. The MLRO is required to assess all of
          the circumstances and, in some cases, it may be helpful to ask the
          customer or others more questions. The choice depends on what is
          already known about the customer and the transaction, and how easy it
          is to make enquiries.
        </p>
        <p>
          Members of staff who consider a transaction or activity to be
          suspicious, would not necessarily be expected to know or to establish
          the exact nature of any underlying criminal offence, or that the
          particular funds or property were those arising from a crime, money
          laundering or terrorist financing. &ldquo;Reasonable grounds to know
          or suspect&rdquo; introduces an objective test of suspicion. The test
          is likely to be met when there are facts or circumstances, known to
          the member of staff, from which a reasonable person (in a payments
          firm) would have inferred knowledge, or formed the suspicion, that
          another person was engaged in money laundering or terrorist financing.
        </p>
        <p>
          A defence of failing to meet the test of suspicion, would be for staff
          to demonstrate that they took reasonable steps to know the customer
          and the rationale for the transaction, activity or instruction.
        </p>
        <p>15.7.2 How to Make a Suspicious Activity Report</p>
        <p>
          Any member of staff, who is suspicious that a transaction may involve
          money laundering or who becomes aware during the course of their work
          that someone else is involved in money laundering, must make a
          disclosure to the MLRO using the 1xbets Suspicious Transaction Report
          form.
        </p>
        <p>
          Once completed, the form should be printed off. The member of staff
          making the report must sign and date the form before giving it to the
          MLRO. The MLRO will take note of the date and time of report receipt.
        </p>
        <p>
          Upon receipt of the form by the MLRO, they will then decide what is to
          be done as a result of the report, e.g., whether the matter must be
          reported to Gaming Control board, Curacao. or not, or further
          enquiries made and record its decision and the reason for it in the
          customer files. The member of staff concerned must be informed of the
          decision and the reasons for it.
        </p>
        <p>
          If the matter is to be referred to GCB, the MLRO or a subordinate will
          complete the FIAU report form and discussing with the reporting member
          of staff how matters with the customer are to be conducted from that
          stage. The GCB report form must be signed off by the MLRO or the CEO.
        </p>
        <p>
          1xbets must not proceed with a transaction whilst we await consent
          from the concerned from GCB it is given 7 working days (not including
          weekends or bank holidays) to consider the report.
        </p>
        <p>
          If we hear nothing then we may continue with the transaction but not
          inform the customer about the report. GCB may give consent to proceed
          earlier than this time limit. They may also refuse consent in which
          case they have a further 31 calendar days (including weekends or bank
          holidays) to further consider the report. After this they must either
          begin proceedings or allow the transaction to continue.
        </p>
        <p>
          The MLRO will submit a softcopy version of the report to the GCB. Any
          paper file for each matter will be kept by the MLRO.
        </p>
        <p>
          There must be no record on the customer file or on the computer system
          which refers in any way to suspicious activity reporting, money
          laundering, etc., to avoid the risk of tipping off. It is a criminal
          offence to inform a customer that a SAR has been submitted, or to
          inform them of an investigation into their affairs.
        </p>
        <p>
          All records of SARs will be kept in the central reporting file, which
          is kept by the MLRO.
        </p>
        <p>15.7.3 How to Identify Suspicious Activity</p>
        <p>
          Transactions may give reasonable grounds to suspect that they are
          related to money laundering or terrorist activity financing regardless
          of the sum of money involved. There is no monetary threshold for
          making a SAR. Suspicious transactions may involve several factors that
          on their own seem insignificant, but together raise suspicion that the
          transaction is related to the commission or attempted commission of a
          money laundering or terrorist activity financing offence, or both. As
          a general guide, a transaction may be connected to money laundering or
          terrorist activity financing when we think that it (or a group of
          transactions) raises questions or gives rise to discomfort,
          apprehension or mistrust.
        </p>
        <p>
          SKYEXCHANGE will examine, as far as reasonably possible, the
          background and purpose of all complex, unusual large transactions, and
          all unusual patterns of transactions, which have no apparent economic
          or lawful purpose.
        </p>
        <p>
          The context in which the transaction occurs or is attempted is a
          significant factor in assessing suspicion and this will vary from one
          customer to another.
        </p>
        <p>
          Transactions will be evaluated in terms of what seems appropriate and
          is within normal practices in business and based on our knowledge of
          the customer. The fact that transactions do not appear to be in
          keeping with normal industry practices may be a relevant factor.
        </p>
        <p>
          Reasonable evaluation of relevant factors, including the knowledge of
          the customer's business, financial history, background and behaviour
          is the basis of assessing suspicion. Behaviour is suspicious, not
          people. We have listed below some indicators to help with this
          assessment.
        </p>
        <p>15.7.4 Indicators of Suspicious Transactions</p>
        <p>
          Certain products, services, activities or channels may pose a higher
          risk of misuse for money laundering. Listed below are several
          anti-money laundering risk indicators or &ldquo;red flags&rdquo; that
          might be grounds for suspicion. The list is not exhaustive and not
          conclusive but will be used as a guide for inquiry and follow-up,
          alongside other material.
        </p>
        <p>
          A single indicator does not necessarily indicate reasonable grounds to
          suspect money laundering or terrorist financing activity. However, if
          several indicators are present during one or a series of transactions,
          then we will take a closer look at other factors before deciding
          whether the transaction must be reported.
        </p>
        <p>
          In the case of a transaction aborted in the belief that the property
          is owned or controlled by or on behalf of a terrorist or a terrorist
          group, then there must be a terrorist property report. If there are
          reasonable grounds to suspect that the transaction is related to an
          attempted commission of a terrorist activity financing or money
          laundering offence, then a suspicious transaction report about the
          attempted transaction is also required.
        </p>
        <p>
          Becoming aware of certain indicators could trigger reasonable grounds
          to suspect that one or more transactions from the past (that had not
          previously seemed suspicious) were related to money laundering or
          terrorist financing.
        </p>
        <p>15.7.4.1 Application and Identification</p>
        <p>
          Information mismatch from application
          <br />
          Application information/address/customer differs from initial
          registration
          <br />
          Inability to provide government issued identification details
          <br />
          Change of address to high-fraud area or to problematic jurisdiction,
          shortly after the application
          <br />
          Lack of reliable third party and/or governmental verification of
          individual
          <br />
          The address indicated (or corroborated) is identified as mail drop or
          other high-risk address, as opposed to a physical street address
          <br />
          Transaction volume, deposits, and cash-outs are inconsistent
          <br />
          15.7.4.2 Account Settlement
        </p>
        <p>
          Large, cross-border wire transfer payments
          <br />
          Settlements/partial settlements from unrelated third parties
          <br />
          Excessive/ongoing large credit refunds
        </p>
      </div>
    </>
  );
};

export default GeneralTerms;
