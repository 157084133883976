import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import io from "socket.io-client";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import MessageBox from "./MessageBox";
import BetSlipContext from "../context/BetSlipContext";
import WalletWrap from "./WalletWrap";
const Header = () => {
  let {
    user,
    user_coins,
    setUserCoins,
    logoutUser,
    setOpenBetsToogle,
    setSettingToogle,
    setIsTv,
    setAnnouncmentToogle,
    isTv,
    setWalletOpen,
    walletOpen,
  } = useContext(AuthProvider);
  const location = useLocation();
  let { betLoader, unMatchedBets, setRefreshCurrentBets } =
    useContext(BetSlipContext);
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);

  const refreshAmount = () => {
    setRefresh(true);
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL}?token=${localStorage.getItem(
        "token"
      )}&userType=front`,
      {
        transports: ["websocket"],
      }
    );
    const coinListener = (message) => {
      setUserCoins(message.results);
      setRefresh(false);
    };
    const forceLogout = (message) => {
      const uniqueId = localStorage.getItem("uniqueId");
      if (uniqueId !== message.results.uniqueId) {
        logoutUser();
      }
    };
    newSocket.emit("getCoins", { user_id: user ? user.user._id : "" });
    newSocket.on("listenGetCoin", coinListener);
    newSocket.on("listenForceLogout", forceLogout);
    newSocket.on("refreshPage", forceRefreshPage);
    return () => newSocket.close();
  };

  const forceRefreshPage = (e) => {
    if (!isEmpty(e)) {
      window.location.reload();
    }
  };
  
  const recallCasinoAmount = async (event) => {
    if (!isEmpty(user)) {
      setLoader(true);
      try {
        const { status, data: response_users } = await apiPost(
          apiPath.withdrawCasinoAmount,
          { amount: casionData ? casionData : user_coins?.casinoCoins }
        );
        if (status === 200) {
          if (response_users.status) {
            if (response_users.data.status === "0000") {
              setLoader(false);
              mainBalanceClick();
              // refreshAmount();
              // setWalletOpen(false);
              // window.location.reload();
              // toast.success(response_users.message);
            } else {
              // setWalletOpen(false);
              setLoader(false);
              // toast.error(response_users.data?.desc);
            }
          } else {
            // setWalletOpen(false);
            setLoader(false);
            // toast.error(response_users.message);
          }
        }
      } catch (err) {
        // conso/le.log("1111111");
        setLoader(false);
        // toast.error(err.response.message);
      }
    }
  };
  const recallEgtCasinoAmount = async (event) => {
    if (!isEmpty(user)) {
      setLoader1(true);
      try {
        const { status, data: response_users } = await apiPost(
          apiPath.withdrawEgtCasinoAmount,
          { amount: 0 }
        );
        if (status === 200) {
          if (response_users.status) {
            setLoader1(false);
            mainEgBalanceClick();
            getCoins();
          } else {
            setLoader1(false);
          }
        }
      } catch (err) {
        setLoader1(false);
      }
    }
  };
  const [casionData, setCasinoData] = useState(0);
  const [egtCasionData, setEgtCasionData] = useState(0);
  const mainBalanceClick = async () => {
    let { status, data: response_users } = await apiPost(apiPath.awcBalance);
    if (status === 200) {
      setCasinoData(response_users.data.balance);
    }
  };
  const mainEgBalanceClick = async () => {
    let { status: status1, data: response_users1 } = await apiPost(
      apiPath.egtBalance
    );
    if (status1 === 200) {
      setEgtCasionData(response_users1.data.balance);
    }
  };
  const getCoins = async () => {
    if (!isEmpty(user)) {
      const { status, data } = await apiGet(apiPath.refreshAmount);
      if (status === 200) {
        if (data.success) {
          setUserCoins({
            exposure: data?.results?.exposure,
            totalCoins: data?.results?.totalCoins,
          });
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      getCoins();
    }
  }, [user]);

  // const MINUTE_MS = 300000;
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // console.log("every 5 mint");
  //     getCoins();
  //   }, MINUTE_MS);

  //   return () => clearInterval(interval);
  // }, []);

  const [message, setMessage] = useState([]);
  const messageList = async () => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    hostname = hostname || "sabaexch.com";

    const { status, data: response_users } = await apiGet(
      apiPath.messageList + "?domain=" + hostname
    );
    if (status === 200) {
      if (response_users.success) {
        setMessage(response_users.results);
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      messageList();
    }
  }, [user]);

  return (
    <>
      {!isEmpty(user) && (
        <header id="headerMain1">
          <ul>
            <li className="li-tv_bet">
              {location?.pathname?.split("/")[1] == "match-details" &&
                isTv.id !== "" && (
                  <a
                    id="openTV"
                    className="a-open_tv ui-link"
                    href="#"
                    onClick={() => setIsTv({ ...isTv, status: true })}
                    style={{ display: isTv.id == "" ? "none" : "flex" }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_MOBILE_URL +
                        "/assets/images/home/transparent.gif"
                      }
                      alt={""}
                    />
                  </a>
                )}
              <a
                id="openBetsBtn"
                onClick={() => {
                  setOpenBetsToogle(true);
                  setRefreshCurrentBets(true);
                }}
                className="a-open_bets ui-link"
                href="javascript:void(0)"
              >
                <img
                  src={
                    process.env.REACT_APP_MOBILE_URL +
                    "/assets/images/home/transparent.gif"
                  }
                  alt={""}
                />
                Bets
                {unMatchedBets?.length > 0 && (
                  <div>
                    <span class="slip-note"></span>
                    <span class="slip-note"></span>
                    <span class="slip-note"></span>
                  </div>
                )}
              </a>
            </li>

            <li className="main-wallet">
              <a
                id="multiWallet"
                href="javascript:void(0)"
                onClick={() => {
                  setWalletOpen(true);
                  mainBalanceClick();
                  mainEgBalanceClick();
                }}
                className={
                  walletOpen ? "a-wallet ui-link open" : "a-wallet ui-link"
                }
              >
                {refresh ? (
                  <p className="loading-bar" id="menuRefreshLoading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </p>
                ) : (
                  <ul id="accountCredit">
                    <li>
                      <span style={{ marginRight: "3px" }}>Main</span>
                      <span id="betCredit">
                        BDT{" "}
                        {user_coins ? user_coins?.totalCoins?.toFixed(2) : 0.0}
                      </span>
                    </li>
                    <li>
                      <span style={{ marginRight: "3px" }}>Exposure</span>
                      <span
                        id="totalExposure"
                        style={{
                          color: user_coins?.exposure > 0 && "white",
                          background: user_coins?.exposure && "red",
                          padding: "2px",
                        }}
                      >
                        {user_coins ? user_coins?.exposure?.toFixed(2) : 0.0}
                      </span>
                    </li>
                    <li className="nums">
                      +<span id="vendorQuantity">5</span>
                    </li>
                  </ul>
                )}
              </a>
              <a
                className="a-refresh ui-link"
                id="menuRefresh"
                href="javascript:void(0)"
                onClick={() => refreshAmount()}
                title="Refresh Main Wallet"
              >
                <img
                  src={
                    process.env.REACT_APP_MOBILE_URL +
                    "/assets/images/home/transparent.gif"
                  }
                  alt={""}
                />
              </a>
            </li>

            <li>
              <a
                onClick={() => setSettingToogle(true)}
                className="a-setting ui-link"
                href="javascript:void(0)"
                title="Setting"
              >
                <img
                  src={
                    process.env.REACT_APP_MOBILE_URL +
                    "/assets/images/home/transparent.gif"
                  }
                  alt={""}
                />
              </a>
            </li>
          </ul>
          <MessageBox />
          <WalletWrap
            user_coins={user_coins}
            recallCasinoAmount={recallCasinoAmount}
            recallEgtCasinoAmount={recallEgtCasinoAmount}
            casionData={casionData}
            egtCasionData={egtCasionData}
            loader={loader}
            loader1={loader1}
          />
        </header>
      )}
      {isEmpty(user) && (
        <header style={{ display: "flex" }}>
          <h1 className="top-logo">
            <a href="/" className="logo-img"></a>
          </h1>
          {/* <a href="#" className="btn-signup ui-link">
            Sign up
          </a>
          <Link to="/Mlogin" className="login-index ui-link">
            Login
          </Link> */}
        </header>
      )}
      {!isEmpty(user) && (
        <div
          id="headerMain2"
          className="marquee-box"
          style={{ display: "flex" }}
        >
          <h4>News</h4>
          <div
            class="marquee"
            onClick={() => {
              setAnnouncmentToogle(message.length > 0 ? true : false);
            }}
          >
            <marquee class="js-marquee-wrapper">
              <div class="js-marquee">
                {message?.length > 0 &&
                  message?.map((item) => {
                    return (
                      <a>
                        <span> {obj.msgDateFormat(item.msgDate)}</span>
                        {item.message}
                      </a>
                    );
                  })}
              </div>
            </marquee>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
