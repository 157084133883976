import React, { useContext } from "react";

import FancyBetRulesWrap from "../components/FancyBetRulesWrap";
import SportsBookRulesWrap from "../components/SportsBookRulesWrap";
import RulesofPremium from "../components/RulesofPremium";
import Backhome from "../components/Backhome";
import Please_login_to_proceed from "../components/Please_login_to_proceed";
import Openbets from "./Openbets";
import Setting from "./Setting";
import Anouncement from "./Anouncement";
import SearchWrap from "./SearchWrap";
import Loader from "./Loader";
import BetPlacePopup from "./BetPlacePopup";
import WalletWrap from "./WalletWrap";
import MessageBox from "./MessageBox";
import BookPosition from "./BookPosition";
import CasinoPopup from "./CasinoPopup";
import MarketDepth from "./MarketDepth";
import GameFooter from "./GameFooter";
import ComingSoonCasinoPopup from "./ComingSoonCasinoPopup";
import EgtCasinoPopup from "./EgtCasinoPopup";
import BetSlipParly from "../pages/MatchDetails/BetSlipParly";
import AuthContext from "../context/AuthContext";
function AllOverlay() {
  const { showParlyBet, parly } = useContext(AuthContext);
  return (
    <>
      {/* <WalletWrap/> */}
      {/* <MessageBox /> */}
      {/* <GameFooter/> */}
      <MarketDepth />
      <CasinoPopup />
      <EgtCasinoPopup />
      <ComingSoonCasinoPopup />
      <BookPosition />
      <BetPlacePopup />
      <SearchWrap />
      <Loader />
      <FancyBetRulesWrap />
      <Anouncement />
      <SportsBookRulesWrap />
      <RulesofPremium />
      <Backhome />
      <Please_login_to_proceed />
      <Openbets />
      <Setting />
      {showParlyBet && parly && <BetSlipParly />}
    </>
  );
}

export default AllOverlay;
