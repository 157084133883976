import { isEmpty, startCase } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import AuthProvider from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { FancyListing, SportsBookListing } from "./BetsListing";
import OpenBetsDetails from "./OpenBetsDetails";
import helper from "../Utils/helpers";
import axios from "axios";
import ParlyFIltered from "./ParlyFIltered";
import obj from "../Utils/helpers";
function Openbets() {
  let { setOpenBetsToogle, openBetsToogle, openBetSelection, setLoader, user } =
    useContext(AuthProvider);
  let {
    messaageBox,
    refreshCurrentBet,
    setRefreshCurrentBets,
    isBetPlaced,
    setIsBetPlaced,
    setMessageBox,
    umMachedBetPlace,
    setUnMatchedBetPlace,
  } = useContext(BetSlipContext);
  const [parlayData, setParlayData] = useState([]);
  const [currentBets, setCurrentBets] = useState([]);
  const [filtered, setFiltered] = useState({});
  const [data, setData] = useState({});
  const [type, setType] = useState("");
  const [betinfo, setBetInfo] = useState(false);
  const [sortbyTime, setSortbyTime] = useState(false);
  const getCurrentBets = async () => {
    if (!isEmpty(user)) {
      const promiseOne = await apiGet(apiPath.mobileCurrentBets);
      const promiseTwo = await apiGet(apiPath.currentParlay);
      Promise.all([promiseOne, promiseTwo]).then((res) => {
        setCurrentBets(res[0]?.data?.results);
        setParlayData(res[1]?.data?.results);
      });
      // if (status === 200) {
      //   if (response_users.success) {
      //     setCurrentBets(response_users.results);
      //     setRefreshCurrentBets(false);
      //   }
      // }
    }
  };
  const getParlayData = async () => {
    if (!isEmpty(user)) {
      const { status, data: response_users } = await apiGet(
        apiPath.currentParlay
      );
      if (status === 200) {
        if (response_users.success) {
          setParlayData(response_users.results);
          setRefreshCurrentBets(false);
        }
      }
    }
  };
  // console.log(currentBets, "currentBets");
  const sortTime = (check) => {
    setSortbyTime(check);
  };
  const handle = (item, type) => {
    setData(item);
    setType(type);
    let obj = currentBets?.find((res) => res?.eventName == item?.eventName);
    setFiltered(
      type == "BetFair"
        ? obj.betList
        : type == "Bookmaker"
        ? obj.bookmakerList
        : type == "Fancy"
        ? obj.sessionBetList
        : type == "SportsBook"
        ? obj.sportBookBetList
        : type == "Toss"
        ? obj?.tossBetList?.map((res) => {
            return { ...res, isMatched: true };
          })
        : type == "Tie"
        ? obj?.tieBetList
        : type == "Parly"
        ? item?.bets
        : ""
    );
  };
  useEffect(() => {
    if (refreshCurrentBet) {
      setRefreshCurrentBets(false);
      handle();
      getCurrentBets();
      // getParlayData();
    }
  }, [refreshCurrentBet]);
  const clearBets = async (id) => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(
      `${apiPath.clearBets}matchBetId=${id}`
    );
    if (status === 200) {
      if (response_users.success) {
        setLoader(false);
        setRefreshCurrentBets(true);
        setIsBetPlaced("clearBet");
        setMessageBox(true);
        // setOpenBetsToogle(true);
        setData({});
        setType("");
        setFiltered({});
        getCurrentBets();
      }
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (umMachedBetPlace) {
      setData({});
      setType("");
      setFiltered({});
      setUnMatchedBetPlace(false);
    }
  }, [umMachedBetPlace]);


  return (
    <>
      <div
        id="openBetsLeftSide"
        className="overlay left-side"
        style={{
          display: openBetsToogle ? "block" : "none",
        }}
      >
        <div
          id="openBetSlide"
          className="side-wrap"
          style={{ display: "flex" }}
        >
          <div id="sideHead" className="side-head">
            <h3 className="a-open_bets">
              <img
                src={
                  process.env.REACT_APP_MOBILE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt={""}
              />
              Open Bets
            </h3>
            <a
              id="close"
              onClick={() => setOpenBetsToogle(false)}
              className="close"
              href="#"
            ></a>
          </div>

          <div id="sideContent" className="side-content">
            {/* <div
              id="openBetMsgBox"
              className="message-wrap success"
              style={{ display: "block" }}
            >
              <div className="message">
                <h4 id="header">Bet Matched</h4>
                <p id="info">
                  <span id="sideType" className="back">
                    Back
                  </span>
                  <strong id="selectionName">England</strong>{" "}
                  <strong id="stake">$1,000</strong> at odds{" "}
                  <strong id="odds">1.29</strong>
                </p>
              </div>
              <a id="close" className="close" href="#">
                Close
              </a>
            </div> */}
            {isEmpty(data) && (
              <ul
                id="eventMenuList"
                style={{ display: "block" }}
                className="menu-list"
              >
                {currentBets?.length > 0 &&
                  currentBets?.map((res) => {
                    return (
                      <>
                        {res?.betList?.length > 0 && (
                          <li
                            id="eventMenuTemplate"
                            className="inplay-off"
                            style={{ display: "block" }}
                          >
                            <a
                              onClick={() => handle(res, "BetFair")}
                              href="javascript:void(0)"
                            >
                              {res?.eventName} - BetFair
                            </a>
                          </li>
                        )}
                        {res?.bookmakerList?.length > 0 && (
                          <li
                            id="eventMenuTemplate"
                            className="inplay-off"
                            style={{ display: "block" }}
                          >
                            <a
                              onClick={() => handle(res, "Bookmaker")}
                              href="javascript:void(0)"
                            >
                              {res?.eventName}- Bookmaker
                            </a>
                          </li>
                        )}
                        {res?.sessionBetList?.length > 0 && (
                          <li
                            id="eventMenuTemplate"
                            className="inplay-off"
                            style={{ display: "block" }}
                          >
                            <a
                              onClick={() => handle(res, "Fancy")}
                              href="javascript:void(0)"
                            >
                              {res?.eventName} - Fancy
                            </a>
                          </li>
                        )}
                        {res?.sportBookBetList?.length > 0 && (
                          <li
                            id="eventMenuTemplate"
                            className="inplay-off"
                            style={{ display: "block" }}
                          >
                            <a
                              onClick={() => handle(res, "SportsBook")}
                              href="javascript:void(0)"
                            >
                              {res?.eventName} - SportsBook
                            </a>
                          </li>
                        )}
                        {res?.tieBetList?.length > 0 && (
                          <li
                            id="eventMenuTemplate"
                            className="inplay-off"
                            style={{ display: "block" }}
                          >
                            <a
                              onClick={() => handle(res, "Tie")}
                              href="javascript:void(0)"
                            >
                              {res?.eventName} - Tie
                            </a>
                          </li>
                        )}
                        {res?.tossBetList?.length > 0 && (
                          <li
                            id="eventMenuTemplate"
                            className="inplay-off"
                            style={{ display: "block" }}
                          >
                            <a
                              onClick={() => handle(res, "Toss")}
                              href="javascript:void(0)"
                            >
                              {res?.eventName} - Toss
                            </a>
                          </li>
                        )}
                      </>
                    );
                  })}
                {parlayData?.length > 0 &&
                  parlayData?.map((res) => {
                    return (
                      <li
                        id="eventMenuTemplate"
                        className="inplay-off"
                        style={{ display: "block" }}
                      >
                        <a
                          onClick={() => handle(res, "Parly")}
                          href="javascript:void(0)"
                        >
                          Bet Placed -{" "}
                          {res?.bets?.length == 0
                            ? ""
                            : res?.bets?.length == 1
                            ? "SINGLE"
                            : res?.bets?.length == 2
                            ? "DOUBLE"
                            : res?.bets?.length == 3
                            ? "TREBLE"
                            : "ACCMULATOR"}{" "}
                          - Parly
                        </a>
                      </li>
                    );
                  })}
              </ul>
            )}
            {!isEmpty(data) && (
              <>
                <div
                  id="pathWrap"
                  className="path-wrap"
                  style={{ display: "flex" }}
                >
                  <a
                    id="goBack"
                    className="go_back"
                    onClick={() => {
                      setData("");
                      setSortbyTime(false);
                      setType("");
                      setBetInfo(false);
                    }}
                    href="javascript:void(0)"
                  ></a>
                  <ul>
                    <li id="eventName">
                      {type == "Parly" ? (
                        <p>
                          {" "}
                          Bet Placed -{" "}
                          {type == "Parly"
                            ? data?.bets?.length == 0
                              ? ""
                              : data?.bets?.length == 1
                              ? "SINGLE"
                              : data?.bets?.length == 2
                              ? "DOUBLE"
                              : data?.bets?.length == 3
                              ? "TREBLE"
                              : "ACCMULATOR"
                            : data?.eventName}{" "}
                          - {type}
                        </p>
                      ) : (
                        <p>
                          {" "}
                          {data?.eventName} - {type}
                        </p>
                      )}
                    </li>
                  </ul>
                </div>
                {type == "Parly" && (
                  <div
                    id="pathWrap"
                    className="path-wrap new-path"
                    style={{ display: "flex" }}
                  >
                    <span> Bet Id : {data?.matchBetId}</span>
                    <span> Stake : {Number(data?.amount)?.toFixed(2)}</span>
                    <span> Odds : {Number(data?.bhav)?.toFixed(2)}</span>
                    <span>
                      {" "}
                      Win/Loss :{" "}
                      {data?.isDeclared ? (
                        <>
                          {data?.result == "loss" ? (
                            <strong
                              className="d-block"
                              style={{ color: "red" }}
                            >
                              {startCase(data?.result)}
                            </strong>
                          ) : (
                            <strong
                              className="d-block"
                              style={{ color: "green" }}
                            >
                              {startCase(data?.result)}
                            </strong>
                          )}
                        </>
                      ) : (
                        "..."
                      )}
                    </span>
                  </div>
                )}
              </>
            )}

            {!isEmpty(data) && (
              <>
                {type == "Fancy" ? (
                  <FancyListing
                    filtered={filtered}
                    sortbyTime={sortbyTime}
                    sortTime={sortTime}
                    betinfo={betinfo}
                    setBetInfo={setBetInfo}
                    type={"Matched"}
                    helper={helper}
                  />
                ) : type == "SportsBook" ? (
                  <SportsBookListing
                    filtered={filtered}
                    sortbyTime={sortbyTime}
                    sortTime={sortTime}
                    betinfo={betinfo}
                    setBetInfo={setBetInfo}
                    type={"Matched"}
                    helper={helper}
                  />
                ) : type == "Parly" ? (
                  <ParlyFIltered
                    filtered={filtered}
                    sortbyTime={sortbyTime}
                    sortTime={sortTime}
                    betType={type}
                    betinfo={betinfo}
                    setBetInfo={setBetInfo}
                    type={"Matched"}
                  />
                ) : (
                  <OpenBetsDetails
                    filtered={filtered}
                    sortbyTime={sortbyTime}
                    sortTime={sortTime}
                    betType={type}
                    betinfo={betinfo}
                    setBetInfo={setBetInfo}
                    type={"Matched"}
                  />
                )}

                {filtered?.some((res) => !res?.isMatched) &&
                  type == "BetFair" && (
                    <OpenBetsDetails
                      filtered={filtered}
                      sortbyTime={sortbyTime}
                      betinfo={betinfo}
                      betType={type}
                      setBetInfo={setBetInfo}
                      sortTime={sortTime}
                      type={"UnMatched"}
                      clearBets={clearBets}
                    />
                  )}
              </>
            )}
            {type == "Parly"
              ? ""
              : type !== "" &&
                !isEmpty(filtered) && (
                  <ul
                    id="openBetOption"
                    className="check-list"
                    style={{ display: "flex" }}
                  >
                    <li>
                      <a
                        id="showBetInfo"
                        onClick={() => setBetInfo(!betinfo)}
                        className={betinfo ? "checked" : ""}
                        href="javascript:void(0)"
                      >
                        Bet Info
                      </a>
                    </li>
                    {filtered?.length > 1 && type !== "SportsBook" && (
                      <li>
                        <a
                          id="showTimeOrder"
                          onClick={() => sortTime(!sortbyTime)}
                          className={sortbyTime ? "checked" : ""}
                          href="javascript:void(0)"
                        >
                          Time Order
                        </a>
                      </li>
                    )}
                    {/* <li name="txnOption">
          <a id="averageOdds" className="" href="javascript:void(0)">
            Average Odds
          </a>
        </li> */}
                  </ul>
                )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Openbets;
