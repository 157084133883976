import React, { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";

const ComingSoonCasinoPopup = () => {
    let {
        setCasinoComingSoonModel, casinoComingSoonModel
    } = useContext(AuthProvider);
    return (
        <div
            id="common_transfer_to_go"
            className="overlay"
            style={{ display: casinoComingSoonModel ? "flex" : "none" }}
        >
            <div id="commonDialogWrap" className="pop-wrap transfer-wrap-slider">

                <div className="available-wrap">
                    <img
                        src={
                            process.env.REACT_APP_MOBILE_URL + "/assets/images/coming-soon.jpg"
                        }
                        style={{ width: "100%", height: "200px" }}
                        alt=""
                    />

                </div>
            </div>
            <div
                id="vendorResetPasswordDiv"
                className="pop-wrap transfer-wrap-slider reset-wrap coming-soon"
            >
                <a id="closeVendorResetPasswordDiv" className="close" onClick={() => {
                    setCasinoComingSoonModel(false);
                }} style={{ top: "-46px" }}>
                    Close
                </a>

            </div>
        </div>
    );
};

export default ComingSoonCasinoPopup;
