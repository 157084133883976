import { isEmpty } from "lodash";
import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import BetSlipContext from "../../context/BetSlipContext";
import obj from "../../Utils/constants";
import BackandLay from "../MatchDetails/BackandLay";
import MatchDetails from "../MatchDetails/MatchDetails";

const Market = ({ data, getMatchDetails,handelAddMultiMarket }) => {
  let { handelShowBetSlip } = useContext(BetSlipContext);

  return data?.map((item) => {
    return (
      <div id="multiMarketsTemplate" style={{ display: "block" }}>
        <div className="game-wrap multi">
          <h4 id="gameInfo" className="game-info">
            {item.eventType && obj.betCheckObj[item.eventType]}
            <ul id="infoIcon" className="info-icon">
              <li id="inPlayIcon" style={{ display: "none" }}>
                <img
                  className="info-inplay"
                  src={process.env.REACT_APP_MOBILE_URL + "/assets/images/transparent.gif"}
                />
                In-Play
              </li>
              <li style={{ display: "none" }}>
                <img
                  className="info-cashout"
                  src={process.env.REACT_APP_MOBILE_URL + "/assets/images/transparent.gif"}
                />{" "}
                Cash Out
              </li>
              <li>
                <span
                  id="lowLiquidityTag"
                  className="game-low_liq"
                  style={{ display: "none" }}
                >
                  Low
                </span>
              </li>
            </ul>
          </h4>

          <table id="gameTeam" className="game-team">
            <tbody>
              <tr>
                <th>
                  {/* <a
                    id="multiMarketPin"
                    className="pin-off-color pin-off"
                    href="javascript:void(0)"
                  ></a> */}

                  <a
                    id="multiMarketPin"
                    // className="pin-off"
                    onClick={() => {
                      handelAddMultiMarket(item.eventId);
                     
                    }}
                    className={"pin-on"}
                    href="javascript:void(0)"
                  ></a>
                  <h4 id="eventName">{item.eventName}</h4>
                </th>
                <td className="team-multi_Go">
                  <Link
                    to={`/match-details/${item.eventId}/${item.marketId}`}
                    id="goToFullMarket"
                    className="multi-Go"
                  />
                </td>
                <td className="team-refresh">
                  <a
                    href="javascript:void(0)"
                    id="refresh"
                    className="refresh"
                    onClick={() => getMatchDetails()}
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="marketBetsWrap" className="bets-wrap">
          <dl id="betsHead" className="bets-selections-head">
            <dt>
              <a className="a-depth" id="marketDepthBtn" href="#">
                Markets Depth
              </a>
              <p id="marketName">Match Odds</p>
            </dt>
            <dd className="mode-land"></dd>
            <dd className="mode-land"></dd>
            <dd>Back</dd>
            <dd>Lay</dd>
            <dd className="mode-land"></dd>
            <dd className="mode-land"></dd>
          </dl>

          {/* <div id="noRace" className="no-data" style={{ display: "none" }}>
            <h3>This race has ended</h3>
            <p></p>
          </div> */}
          {/* <MatchDetails
            selections={item.runners}
            // back_odds={back_odds}
            // lay_odds={lay_odds}
            details={data}
          /> */}
          {item.runners.length > 0 &&
            item.runners.map((res) => {
              return (
                <dl
                  id="selectionTemplate"
                  className="bets-selections"
                  style={{ display: "flex" }}
                >
                  <dt>
                    {/* <div
        id="horseInfo"
        className="horse-info"
        style={{ display: "none" }}
      >
        <ul id="horseBox" className="horse-box">
          <li id="clothNumberAlpha"></li>
          <li id="stallDraw"></li>
        </ul>
        <div id="uniform" className="uniform">
          <img src="" />
        </div>
        <ul id="horseName" className="horse-name">
          <li id="runnerName"></li>
          <li id="jockyName"></li>
        </ul>
      </div> */}

                    <h4 id="runnerName">{res.RunnerName}</h4>
                    <ul id="winLoss">
                      <li
                        id="withoutBet"
                        className="win"
                        style={{ display: "none" }}
                      ></li>
                      <li
                        id="lossWithoutBet"
                        className="win"
                        style={{ display: "none" }}
                      ></li>
                      <li
                        id="withBet"
                        className="win"
                        style={{ display: "none" }}
                      ></li>
                      <li
                        id="lossWithBet"
                        className="win"
                        style={{ display: "none" }}
                      ></li>
                      <li
                        id="zeroProfit"
                        className="win"
                        style={{ display: "none" }}
                      ></li>
                      <li
                        id="zeroLiability"
                        className="win"
                        style={{ display: "none" }}
                      ></li>
                      <li
                        id="zeroProfitWithBet"
                        className="win"
                        style={{ display: "none" }}
                      ></li>
                      <li
                        id="zeroLiabilityWithBet"
                        className="win"
                        style={{ display: "none" }}
                      ></li>
                    </ul>
                  </dt>
                  {/* <dd id="suspend" className="suspend">
      <p>Suspend</p>
    </dd>
    <dd id="closed" className="suspend">
      <p>Closed</p>
    </dd>
    <dd id="nonRunner" className="suspend">
      <p>Non Runner</p>
    </dd>
    <dd id="back_3" className="mode-land">
      <a className="back-3" href="#">
        <span></span>
      </a>
    </dd>
    <dd id="back_2" className="mode-land">
      <a className="back-2" href="#">
        <span></span>
      </a>
    </dd>
    <dd id="back_1">
      <a className="back-1" href="#">
        <span></span>
      </a>
    </dd>
    <dd id="lay_1">
      <a className="lay-1" href="#">
        <span></span>
      </a>
    </dd>
    <dd id="lay_2" className="mode-land">
      <a className="lay-2" href="#">
        <span></span>
      </a>
    </dd>
    <dd id="lay_3" className="mode-land">
      <a className="lay-3" href="#">
        <span></span>
      </a>
    </dd> */}

                  {/* <dd id="back_3" className="mode-land">
                    <a className="back-3" href="#" fullmarketodds="5.6">
                      5.6<span> 5,283</span>
                    </a>
                  </dd>
                  <dd id="back_2" className="mode-land">
                    <a className="back-2 spark" href="#" fullmarketodds="5.7">
                      5.7<span> 5,337</span>
                    </a>
                  </dd>
                  <dd id="back_1">
                    <a className="back-1" href="#" fullmarketodds="5.8">
                      5.8<span> 1,758</span>
                    </a>
                  </dd>
                  <dd id="lay_1">
                    <a className="lay-1" href="#" fullmarketodds="5.9">
                      5.9<span> 4,435</span>
                    </a>
                  </dd>
                  <dd id="lay_2" className="mode-land">
                    <a className="lay-2" href="#" fullmarketodds="6">
                      6<span> 8,310</span>
                    </a>
                  </dd>
                  <dd id="lay_3" className="mode-land">
                    <a className="lay-3" href="#" fullmarketodds="6.2">
                      6.2<span> 27,189</span>
                    </a>
                  </dd> */}

                  <BackandLay
                    market={"multi"}
                    back={!isEmpty(item?.back_odds) ? item.back_odds : []}
                    lay={!isEmpty(item?.lay_odds) ? item.lay_odds : []}
                    item={res}
                    details={item}
                    handelShowBetSlip={handelShowBetSlip}
                  />
                </dl>
              );
            })}
        </div>
      </div>
    );
  });
};

export default Market;
