import { isEmpty } from "lodash";
import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Aboutus from "../components/staticContent/Aboutus";
import GeneralTerms from "../components/staticContent/GeneralTerms";
import Kyc from "../components/staticContent/Kyc";
import PrivacyPolicy from "../components/staticContent/PrivacyPolicy";
import ResponsibleGaming from "../components/staticContent/ResponsibleGaming";
import RulesRegulation from "../components/staticContent/RulesRegulation";
import AuthProvider from "../context/AuthContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { AiFillEye,AiFillEyeInvisible } from "react-icons/ai";
function Login(props) {
  let {
    loginUser,
    isLoged,
    open,
    setOpen,
    content,
    slug,
    setSlug,
    handleContentToggle,
  } = useContext(AuthProvider);
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword =(e)=>{
    e.preventDefault()
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    unregister,
    reset: reset2,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });
  const [getValidateCode, setValidateCode] = useState("");

  const [data, setData] = useState({});
  const onSubmit2 = (data) => {
    loginUser(data);
  };

  const getSetting = async (event) => {
    try {
      const { status, data: response_users } = await apiGet(apiPath.getSetting);
      if (status === 200) {
        if (response_users.success) {
          setData(response_users.results);
        }
      }
    } catch (err) { }
  };
  useEffect(() => {
    getSetting();
  }, []);
  return (
    <div>
      <div className="login_main">
        <header className="login-head">
          <Link to="/" className="close"></Link>
          <h1>SKYEXCHANGE</h1>
          <div id="powerWrap" className="betfair-wrap">
            <p>Powered by</p>
            <span>
              {/* <img
                src={
                  process.env.REACT_APP_MOBILE_URL +
                  "/assets/images/betfair_black.png"
                }
                alt=""
                draggable="false"
              /> */}
            </span>
          </div>
        </header>
        <Form onSubmit={handleSubmit2(onSubmit2)}>
          <dl className="form-login">
            <Form.Group
              style={{ marginBottom: "10px" }}
              id="loginNameErrorClass"
            >
              <Form.Control
                type="text"
                autocomplete="off"
                // id="loginName"
                className={errors2.username ? "is-invalid" : ""}
                {...register2("username", {
                  required: "Please enter Username",
                })}
                placeholder="Username"
              />
              {errors2.username && errors2?.username?.message && (
                <label className="invalid-feedback text-left">
                  {errors2.username.message}
                </label>
              )}
            </Form.Group>
            <Form.Group
              style={{ marginBottom: "10px"}}
              id="passwordErrorClass"
            >
              <div style={{ position:'relative'}}>
              <Form.Control
                type={passwordType ?passwordType:"password"}
                autoComplete="off"
                // id="password"
                className={errors2.password ? "is-invalid" : ""}
                {...register2("password", {
                  required: "Please enter password",
                })}
                data-role="none"
                placeholder="Password"
              />
              <div className="input-group-btn" style={{position:'absolute', right:'0', top:'0', bottom:'0px'}}>
                     <span className="btn btn-outline-primary" onClick={togglePassword} style={{backgroundColor:'transparent', border:'0',padding:'8px', paddingTop:'3px', height:'72%'}}>
                     { passwordType==="password"?<AiFillEyeInvisible/> :  <AiFillEye/>}
              </span>
              </div>
              </div>
              {errors2.password && errors2.password.message && (
                <label className="invalid-feedback text-left">
                  {errors2.password.message}
                </label>
              )}
            </Form.Group>

            {/* <dd>
              <input name="valid" type="hidden" id="valid" />
            </dd> */}
            <dd>
              <Button type="submit" className="btn-send w-100" id="loginBtn">
                Login
              </Button>
            </dd>
            {isLoged == "failed" && (
              <dd id="errorMsg" class="state">
                Login name or password is invalid! Please try again.
              </dd>
            )}
          </dl>
        </Form>

        <ul className="policy-link login-policy">
          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("about");
              }}
            >
              About us
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("term");
              }}
            >
              Terms and Conditions
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("kyc");
              }}
            >
              KYC
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("privacy");
              }}
            >
              Privacy Policy
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("rules");
              }}
            >
              Rules and Regulations
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("responsible");
              }}
            >
              Responsible Gaming
            </Link>
          </li>
          {/* <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">Self-Exclusion Policy</a>
            </li>
            <li>
              <a href="#">Underage Policy</a>
            </li> */}
        </ul>

        {content && (
          <div className="frame-wrap">
            <div className="news-wrap news-wrap-frame">
              {slug === "about" && <Aboutus />}
              {slug === "term" && <GeneralTerms />}
              {slug === "kyc" && <Kyc />}
              {slug === "privacy" && <PrivacyPolicy />}
              {slug === "rules" && <RulesRegulation />}
              {slug === "responsible" && <ResponsibleGaming />}
              <footer>
                <Link
                  to="#"
                  className="btn-send"
                  onClick={() => {
                    handleContentToggle();
                    setSlug("");
                  }}
                >
                  CLOSE
                </Link>
              </footer>
            </div>
          </div>
        )}

        <div id="supportWrap" className="support-wrap mb-0 pb-0 login-support">
          <div className="support-service">
            <a
              id="support_whatsapp"
              href="javascript:void(0)"
              onClick={() => setOpen(true)}
              className={open ? "support-whatsapp" : "support-whatsapp"}
            // className="support-whatsapp open"
            >
              <img
                src={
                  process.env.REACT_APP_MOBILE_URL +
                  "/assets/images/home/transparent.gif"
                }
                title="WhatsApp"
                alt=""
              />
            </a>
            <a
              id="support_facebook"
              href="javascript:void(0)"
              onClick={() => setOpen(false)}
              className={open ? "support-fb " : "support-fb open"}
            >
              <img
                src={
                  process.env.REACT_APP_MOBILE_URL +
                  "/assets/images/home/transparent.gif"
                }
                title="Facebook"
                alt=""
              />
            </a>
          </div>

          <div class="support-info">
            {open ? (
              <div id="supportDetail_whatsapp" class="support-detail open">
                {/* <a href="javascript:void(0)">
                  <span>+0000000000</span>
                </a>

                <a href="javascript:void(0)">
                  <span>+0000000000</span>
                </a> */}
              </div>
            ) : (
              <div id="supportDetail_facebook" class="support-detail">
                {/* <a href="https://www.facebook.com/groups/9exchenge">
                  <span>www.facebook.com/groups/9exchenge</span>
                </a> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
